import { hash as hashName, injectResourceUrl as resourceRootUrl, wasmResSuffix } from '../../resource/constants';

(function (sdk: any): void {
  if (!sdk) {
    console.warn('please load main sdk script before this!');
    return;
  }

  (window as any).ByteWebARClient = (window as any).ByteWebARClient || {};

  // init resource and plugin config
  const REROURCE_PATH_CONFIG = {
    SDK_RESOURCE_URL: `${resourceRootUrl}`,
    SDK_HASH: `${hashName}`,
    SDK_MAKEUP: {
      DEFAULT_SHIMMER_URL: '/image/img.shimmer.png',
      DEFAULT_LIPTEMPLATE_URL: '/image/img.mask.png',
      LUT_IMAGE_URL: '/image/img.lut.png'
    },
    SDK_FACE: {
      JS_URL: '/wasm/face_wasm.[hash].js',
      WASM_URL: `/wasm/face_wasm.[hash].wasm${wasmResSuffix}`,
      DATA_URL: `/wasm/face_wasm.[hash].data${wasmResSuffix}`
    },
    SDK_FOOT: {
      JS_URL: '/wasm/foot_wasm.[hash].js',
      SIMD_WASM_URL: `/wasm/foot_wasm_simd.[hash].wasm${wasmResSuffix}`,
      NO_SIMD_WASM_URL: `/wasm/foot_wasm.[hash].wasm${wasmResSuffix}`,
      DATA_URL: `/wasm/foot_wasm.[hash].data${wasmResSuffix}`
    },
    SDK_HEAD: {
      JS_URL: '/wasm/head_wasm.[hash].js',
      WASM_URL: `/wasm/head_wasm.[hash].wasm${wasmResSuffix}`,
      DATA_URL: `/wasm/head_wasm.[hash].data${wasmResSuffix}`
    },
    SDK_OBJECT: {
      JS_URL: '/wasm/object_wasm.[hash].js',
      WASM_URL: `/wasm/object_wasm.[hash].wasm${wasmResSuffix}`,
    },
    SDK_WORLD: {
      JS_URL: '/wasm/world_wasm.[hash].js',
      WASM_URL: `/wasm/world_wasm.[hash].wasm${wasmResSuffix}`,
      DATA_URL: `/wasm/world_wasm.[hash].data${wasmResSuffix}`
    },
    SDK_WRIST: {
      JS_URL: '/wasm/wrist_wasm.[hash].js',
      WASM_URL: `/wasm/wrist_wasm.[hash].wasm${wasmResSuffix}`,
      DATA_URL: `/wasm/wrist_wasm.[hash].data${wasmResSuffix}`
    }
  };

  const PLUGIN_PATH_CONFIG = {
    plugins: [
      {
        pluginID: 'ThreeJsPlugin',
        scriptsURL: `${resourceRootUrl}/externals/webar.three.iife.${hashName}.js`
      },
      {
        pluginID: 'FaceTrackerPlugin',
        scriptsURL: `${resourceRootUrl}/externals/webar.facetracker.iife.${hashName}.js`
      },
      {
        pluginID: 'FaceMakeupPlugin',
        scriptsURL: `${resourceRootUrl}/externals/webar.facemakeup.iife.${hashName}.js`
      },
      {
        pluginID: 'FootTrackerPlugin',
        scriptsURL: `${resourceRootUrl}/externals/webar.foottracker.iife.${hashName}.js`
      },
      {
        pluginID: 'HeadTrackerPlugin',
        scriptsURL: `${resourceRootUrl}/externals/webar.headtracker.iife.${hashName}.js`
      },
      {
        pluginID: 'WristTrackerPlugin',
        scriptsURL: `${resourceRootUrl}/externals/webar.wristtracker.iife.${hashName}.js`
      },
      {
        pluginID: 'WorldTrackerPlugin',
        scriptsURL: `${resourceRootUrl}/externals/webar.worldtracker.iife.${hashName}.js`
      },
      {
        pluginID: 'ObjectTrackerPlugin',
        scriptsURL: `${resourceRootUrl}/externals/webar.objecttracker.iife.${hashName}.js`
      }
    ]
  };
  sdk.WEBAR_CONSTANTS.REROURCE_PATH_CONFIG = REROURCE_PATH_CONFIG;
  sdk.WEBAR_CONSTANTS.PLUGIN_PATH_CONFIG = PLUGIN_PATH_CONFIG;
  sdk.WEBAR_CONSTANTS.SCRIPT_URL.AMMOJS = `${resourceRootUrl}/builds/thirdparty/ammo.wasm.js`;

  sdk.pluginManager.setConfiguration(PLUGIN_PATH_CONFIG);

})((window as any).WebARMainSDK);