declare const HASH_TIME: any;
declare const OUTPUT: any;
declare const RESOURCE_URL: any;
declare const HASH: any;
declare const WASM_RES_SUFFIX: any;
declare const VERSION: any;

export const hashTime = HASH_TIME;
export const output = OUTPUT;
export const injectResourceUrl = RESOURCE_URL;
export const hash = HASH;
export const wasmResSuffix = WASM_RES_SUFFIX;
export const version = VERSION;